import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProfileTemplate = ({
  data: { previous, next, site, markdownRemark: post },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <div className="p-5 md:p-10 text-sm bg-zinc-100 overflow-hidden">
        <article
          className=""
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <a href={post.frontmatter.website} className="profileWebsite" target="_blank" rel="noopener noreferrer nofollow">
              <img src={post.frontmatter.logo} alt={`${post.frontmatter.title} Logo`} className="w-44 mb-5" />
            </a>
            <h1 className="font-semibold text-lg">{post.frontmatter.title}</h1>
            <div className="">{post.frontmatter.tagline}</div>
            <br />
            <div className="profileAddress">Address: {post.frontmatter.address}</div>
            <div className="profileWebsite">Website: {post.frontmatter.website && <a href={post.frontmatter.website} className="profileWebsite" target="_blank" rel="noopener noreferrer nofollow">{post.frontmatter.website}</a>}</div>
            <br />
          </header>
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </article>
      </div>
    </Layout>
  )
}

export const Head = ({ data: { markdownRemark: post } }) => {
  return (
    <Seo
      title={post.frontmatter.title}
      description={post.frontmatter.description || post.excerpt}
    />
  )
}

export default ProfileTemplate

export const query = graphql`
  query BlogPostBySlug(
    $id: String!
    # $previousPostId: String
    # $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }, fileAbsolutePath: { regex: "/content/agency/profile/" }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tagline
        logo
        website
        address
      }
    }
  }
`